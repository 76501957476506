// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwdCVbXS8DBNSeo3EngZLzkP2cvSkFP_Y",
  authDomain: "travel-180f9.firebaseapp.com",
  projectId: "travel-180f9",
  storageBucket: "travel-180f9.appspot.com",
  messagingSenderId: "114756293983",
  appId: "1:114756293983:web:7f0a9d31e2e645ebf092ea",
  measurementId: "G-83BMLGEHTE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();