import {
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
  } from '@mui/material';
  import { useState } from 'react';
  import { useValue } from '../../context/ContextProvider';
  import TagInfoField from './TagInfoField';
  
  
  const AddTagDetails = () => {
    const {
        state: {
            tagDetails: { Tagtitle},
        },
        dispatch,
    }  = useValue();


    /*const [costType, setCostType] = useState(price ? 1 : 0);
    const handleCostTypeChange = (e) => {
      const costType = Number(e.target.value);
      setCostType(costType);
      if (costType === 0) {
        dispatch({ type: 'UPDATE_DETAILS', payload: { price: 0 } });
      } else {
        dispatch({ type: 'UPDATE_DETAILS', payload: { price: 15 } });
      }
    };
    const handlePriceChange = (e) => {
      dispatch({ type: 'UPDATE_DETAILS', payload: { price: e.target.value } });
    };*/
    return ( 
      <Stack
        sx={{
          alignItems: 'center',
          '& .MuiTextField-root': { width: '100%', maxWidth: 500, m: 1 },
        }}
      >

        <TagInfoField
        mainProps={{ name: 'Tagtitle', label: 'Title', value: Tagtitle }}
        minLength={1}
        />
  
      </Stack>
    );
  };
  
  export default AddTagDetails;
  
        