import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useValue } from '../../context/ContextProvider';
import { getPoints } from '../../actions/point';
import Supercluster from 'supercluster';
import './cluster.css';
import {  Tooltip } from '@mui/material';
import GeocoderInput from '../sidebar/GeocoderInput';
import PopupPoint from './PopupPoint';
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup
} from 'react-map-gl';
import Geocoder from '../addPoint/addLocation/Geocoder';
import { filterPointsByTag } from '../../actions/utils/mapUtils';

// todo: Supercluster creates the cluster for Mapbox
const supercluster = new Supercluster({
  radius: 15,
  maxZoom: 30,
});

const ClusterMap = () => {
  const {
    state: { points, filteredPoints, filteredTags },
    dispatch,
    mapRef,
  } = useValue();
  const [mapPoints, setMapPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);
  const [popupInfo, setPopupInfo] = useState(null); 

  useEffect(() => {
    getPoints(dispatch);
  }, []);


  // Updated useEffect to add filtering on points
  useEffect(() => {

    if (filteredTags.length > 0) {
      const filteredTagPoints = filterPointsByTag(points, filteredTags)
      dispatch({type: 'UPDATE_FILTERED_POINTS', payload: filteredTagPoints})

      const pointsArr = filteredTagPoints.map((point) => ({
        type: 'Feature',
        properties: {
          cluster: false,
          pointId: point._id,
          price: point.price,
          title: point.title,
          description: point.description,
          pointTags: point.pointTags,
          lng: point.lng,
          lat: point.lat,
          images: point.images,
          uPhoto: point.uPhoto,
          uName: point.uName,
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(point.lng), parseFloat(point.lat)],
        },
      }));
      setMapPoints(pointsArr)
    }
    else {
      // If there are no tags for the cluster map.
      dispatch({type: 'UPDATE_FILTERED_POINTS', payload: points})

      const pointsArray = filteredPoints.map((point) => ({
        type: 'Feature',
        properties: {
          cluster: false,
          pointId: point._id,
          price: point.price,
          title: point.title,
          description: point.description,
          pointTags: point.pointTags,
          lng: point.lng,
          lat: point.lat,
          images: point.images,
          uPhoto: point.uPhoto,
          uName: point.uName,
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(point.lng), parseFloat(point.lat)],
        },
      }));
      setMapPoints(pointsArray);
    }

  }, [filteredPoints, filteredTags, dispatch, points]);

  useEffect(() => {
    supercluster.load(mapPoints);
    setClusters(supercluster.getClusters(bounds, zoom));
  }, [mapPoints, zoom, bounds]);

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);
  return (
    <Box
    sx={{
      height: 500,
      position: 'relative',
    }}
  >
    <ReactMapGL
      initialViewState={{ latitude: 51.5072, longitude: 0.1276 }}
      mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      ref={mapRef}
      onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
    >
      {clusters.map((cluster) => {
        const { cluster: isCluster, point_count } = cluster.properties;
        const [longitude, latitude] = cluster.geometry.coordinates;


        // todo: Conditional render for if it is a cluster or of it is a point
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              longitude={longitude}
              latitude={latitude}
            >
              <div
                className="cluster-marker"
                style={{
                  width: `${10 + (point_count / mapPoints.length) * 1}px`,
                  height: `${10 + (point_count / mapPoints.length) * 1}px`,
                }}
                onClick={() => {
                  const zoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  mapRef.current.flyTo({
                    center: [longitude, latitude],
                    zoom,
                    speed: 1,
                  });
                }}
              >
                {point_count}
              </div>
            </Marker>
          );
        }

        return (
          <Marker
            key={`point-${cluster.properties.pointId}`}
            longitude={longitude}
            latitude={latitude}
          >
          <Tooltip title={cluster.properties.title}>
          <div
                className="point-marker"
                style={{
                width: `${10 + (point_count / mapPoints.length) * 20}px`,
                height: `${10 + (point_count / mapPoints.length) * 20}px`,
                }}
                
                onClick={() => setPopupInfo(cluster.properties)}
                ></div>
            </Tooltip>

          </Marker>
        );
      })}
      
      {popupInfo && (
        <Popup
          longitude={popupInfo.lng}
          latitude={popupInfo.lat}
          maxWidth="auto"
          closeOnClick={false}
          focusAfterOpen={false}
          onClose={() => setPopupInfo(null)}
        >
          <PopupPoint {...{ popupInfo }} />
        </Popup>
      )}
              <NavigationControl position="bottom-right" />
        <GeolocateControl
          position="top-left"
          trackUserLocation
          onGeolocate={(e) =>
            dispatch({
              type: 'UPDATE_LOCATION',
              payload: { lng: e.coords.longitude, lat: e.coords.latitude },
            })
          }
        />
        <Geocoder />
    </ReactMapGL>
    </Box>
  );
};

export default ClusterMap;