import BottomNav from '../components/BottomNav';
import NavBar from '../components/NavBar';
import Login from '../components/user/Login';
import { useValue } from '../context/ContextProvider';
import Landing from './Landing';

const Home = () => {

  const {
    state: {currentView}
  } = useValue()

  if (currentView === 'Landing') return <Landing />

  return (
    <>
      <Login />
      <NavBar />
      <BottomNav />
    </>
  );
};

export default Home;