import React, { useState } from 'react';
import './about.css';


const AboutUs = () => {

    return (
<>
  <div className="about-section">
    <h3>The Cycling Road Map is meant to act as a cartographic representation of cycling history.
        
    </h3>
    <p>
    Points are added nearly everyday to help chronicle the sport in a new way.
    </p>
  </div>

  <div className="row">

    <div className="column">
      <div className="card">
        <div className="container">
          <h2>Creator</h2>
          <p className="title">Sheridan Norris</p>
          <p>Cycling enthusiast.</p>
          <p>You can reach us at: </p>
          <p>thecyclingroadmap@gmail.com
          </p>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="card">
        <div className="container">
          <h2>Our History</h2>
          <p>Founded in 2024, our first points were added in January with over 60 new points each month.</p>

          <p>
          </p>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="card">
        <div className="container">
          <h2>How to Use</h2>
          <p>Use the tag selection in the Map to select how you want the points to be filtered.</p>
          <p>
            If you want to see points for Summits in the 2024 Tour de France, choose:
          </p>
          <ul>
            <li>Summit</li>
            <li>Tour de France 2024</li>
          </ul>
          <p>Other interesting tags to use are:</p>
          <ul>
            <li>Memorial</li>
            <li>Cobbles</li>
            <li>Berg</li>
            <li>Museum</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</>
      );
      

}
export default AboutUs;