import { Box, Drawer, IconButton, styled, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import TagFilter from './TagFilter';
import { useValue } from '../../context/ContextProvider';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Sidebar = ({ isOpen, setIsOpen }) => {
  /*const { containerRef } = useValue();*/
  return (
    <Drawer variant="persistent" hideBackdrop={true} open={isOpen} 
    PaperProps={{ style: { width: '30%' } }}>


<DrawerHeader>
<Typography>Apply Tags to Filter:</Typography>
<IconButton onClick={() => setIsOpen(false)}>
<ChevronLeft fontSize="large" />
</IconButton>
</DrawerHeader>




      <Box sx={{ width: 400, p: 3 }}>
      <TagFilter/>
      </Box>
      
    </Drawer>

  );
  
};

export default Sidebar;