import {
  Card,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,

} from '@mui/material';
import { useValue } from '../../context/ContextProvider';

import { useState, useEffect } from 'react';
import { filterPointsByTag } from '../../actions/utils/mapUtils';

const Points = () => {
  const {
    state: { points, filteredPoints, filteredTags },
    dispatch,
  } = useValue();

  const [mapPoints, setMapPoints] = useState([])
  
  useEffect(() => {
    dispatch({type: "START_LOADING"})
    if (filteredTags.length > 0) {
      const filteredTagPoints = filterPointsByTag(points, filteredTags)
      dispatch({type: 'UPDATE_FILTERED_POINTS', payload: filteredTagPoints})
      setMapPoints(filteredTagPoints)
    } else {
      dispatch({type: 'UPDATE_FILTERED_POINTS', payload: points})
      setMapPoints(points)
    }
    dispatch({type: "END_LOADING"})
    
  }, [filteredPoints, filteredTags, points, dispatch])


  return (
    <Container>
      <ImageList
        gap={12}
        sx={{
          mb: 8,
          gridTemplateColumns:
            'repeat(auto-fill, minmax(280px, 1fr))!important',
        }}
      >
        {mapPoints.map((point) => (
          <Card key={point._id}>
            <ImageListItem sx={{ height: '100% !important' }}>
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)',
                }}
              />
              <img
                src={point.images[0]}
                alt={point.title}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch({ type: 'UPDATE_POINT', payload: point })}
              />
              <ImageListItemBar
                title={point.title}
              />
            </ImageListItem>
          </Card>
        ))}
      </ImageList>
    </Container>
  );
};

export default Points;