import deleteImages from './utils/deleteImages';
import fetchData from './utils/fetchData';

const url = process.env.REACT_APP_SERVER_PROD_URL + '/point';

// TODO: POINT CREATION AND RETRIEVAL.
export const createPoint = async (point, currentUser, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    { url, body: point, token: currentUser?.token },
    dispatch
  );
  if (result) { 
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'The point has been added successfully',
      },
    });
    clearPoint(dispatch, currentUser);
    dispatch({ type: 'UPDATE_SECTION', payload: 0 });
    dispatch({ type: 'UPDATE_POINT', payload: result });
  }

  dispatch({ type: 'END_LOADING' });
};

// TODO: GETTING THE POINTS
export const getPoints = async (dispatch) => {
  dispatch({ type: 'START_LOADING' });
  const result = await fetchData({ url, method: 'GET' }, dispatch);
  if (result) {
    dispatch({ type: 'UPDATE_POINTS', payload: result });
  }
  dispatch({ type: 'END_LOADING' });
};

export const deletePoint = async (point, currentUser, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    { url: `${url}/${point._id}`, method: 'DELETE', token: currentUser?.token },
    dispatch
  );
  if (result) {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'The point has been deleted successfully',
      },
    });

    dispatch({ type: 'DELETE_POINT', payload: result._id });
    deleteImages(point.images, point.uid);
  }

  dispatch({ type: 'END_LOADING' });
};

export const updatePoint = async (
  point,
  currentUser,
  dispatch,
  updatedPoint,
  deletedImages
) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    {
      url: `${url}/${updatedPoint._id}`,
      method: 'PATCH',
      body: point,
      token: currentUser?.token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'The point has been updated successfully',
      },
    });

    clearPoint(dispatch, currentUser, deletedImages, updatedPoint);
    dispatch({ type: 'UPDATE_SECTION', payload: 0 });
    dispatch({ type: 'UPDATE_POINT', payload: result });
  }

  dispatch({ type: 'END_LOADING' });
};

export const clearPoint = (
  dispatch,
  currentUser,
  images = [],
  updatedPoint = null
) => {
  dispatch({ type: 'RESET_POINT' });
  localStorage.removeItem(currentUser.id);
  if (updatedPoint) {
    deleteImages(images, updatedPoint.uid);
  } else {
    deleteImages(images, currentUser.id);
  }
};

export const storePoint = (
  location,
  details,
  images,
  updatedPoint,
  deletedImages,
  addedImages,
  userId
  
) => {
  if (
    location.lng ||
    location.lat ||
    details.price ||
    details.title ||
    details.description ||
    details.pointTags ||
    images.length
  ) {
    localStorage.setItem(
      userId,
      JSON.stringify({
        location,
        details,
        images,
        updatedPoint,
        deletedImages,
        addedImages,
      })
    );
    return true;
  } else {
    return false;
  }
};