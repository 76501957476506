import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import reducer from './reducer';

const initialState = {
  currentUser: null,
  openLogin: false,
  loading: false,
  alert: { open: false, severity: 'info', message: '' },
  profile: { open: false, file: null, photoURL: '' },
  images: [],
  details: { title: '', description: '', price: 0, pointTags: [] },
  location: { lng: 0, lat: 0 },
  updatedTag: {Tagtitle: ''},
  updatedPoint: null,
  deletedImages: [],
  addedImages: [],
  points: [],
  priceFilter: 50,
  addressFilter: null,
  filteredPoints: [],
  filteredPolylines: [],
  filteredTags: [],
  tagDetails: {Tagtitle: ''},
  point: null, 
  users: [],
  section: 0,
  polyline_details: { polyline_title: '', polyline_description: '', polyline_encoded: '', polyline_tags: [] },
  currentView: 'Landing'
}; 

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

// todo: context provider for global state
const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mapRef = useRef();
  const containerRef = useRef();
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      dispatch({ type: 'UPDATE_USER', payload: currentUser });
    }
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      const point = JSON.parse(localStorage.getItem(state.currentUser.id));
      if (point) {
        dispatch({ type: 'UPDATE_LOCATION', payload: point.location });
        dispatch({ type: 'UPDATE_DETAILS', payload: point.details });
        dispatch({ type: 'UPDATE_IMAGES', payload: point.images });
        dispatch({ type: 'UPDATE_UPDATED_POINT', payload: point.updatedPoint });
        dispatch({
          type: 'UPDATE_DELETED_IMAGES',
          payload: point.deletedImages,
        });
        dispatch({ type: 'UPDATE_ADDED_IMAGES', payload: point.addedImages });
      }
    }
  }, [state.currentUser]);

  return (
    <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;