import fetchData from './utils/fetchData';

const url = process.env.REACT_APP_SERVER_PROD_URL + '/tag';

export const createTag = async (tag, currentUser, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    { url, body: tag, token: currentUser?.token },
    dispatch
  );
  if (result) {
    dispatch({
      type: 'UPDATE_ALERT', 
      payload: {
        open: true,
        severity: 'success',
        message: 'The tag has been added successfully',
      },
    });
    dispatch({ type: 'RESET_TAG' });
  }

  dispatch({ type: 'END_LOADING' });
};

export const getTags = async (dispatch) => {
  dispatch({ type: 'START_LOADING' });
  const result = await fetchData({ url, method: 'GET' }, dispatch);
  if (result) {
    dispatch({ type: 'UPDATE_TAGS', payload: result });
  }
  dispatch({ type: 'END_LOADING' });
};

export const deleteTag = async (tag, currentUser, dispatch) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    { url: `${url}/${tag._id}`, method: 'DELETE', token: currentUser?.token },
    dispatch
  );
  if (result) {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'The tag has been deleted successfully',
      },
    });

    dispatch({ type: 'DELETE_TAG', payload: result._id });
  }

  dispatch({ type: 'END_LOADING' });
};

export const updateTag = async (
  tag,
  currentUser,
  dispatch,
  updatedTag
) => {
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData(
    {
      url: `${url}/${updatedTag._id}`,
      method: 'PATCH',
      body: tag,
      token: currentUser?.token,
    },
    dispatch
  );
  if (result) {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'success',
        message: 'The tag has been updated successfully',
      },
    });

    clearTag(dispatch, currentUser, updatedTag);
    dispatch({ type: 'UPDATE_SECTION', payload: 0 });
    dispatch({ type: 'UPDATE_TAG', payload: result });
  }

  dispatch({ type: 'END_LOADING' });
};

export const clearTag = (
  dispatch,
  currentUser,
  updatedTag = {Tagtitle: ''}
) => {
  dispatch({ type: 'RESET_TAG' });
  localStorage.removeItem(currentUser.id);
};

export const storeTag = (

  tag,
  updatedTag,
  userId
  
) => {
  if (
    tag.Tagtitle
  ) {
    localStorage.setItem(
      userId,
      JSON.stringify({
        tag,
        updatedTag
      })
    );
    return true;
  } else {
    return false;
  }
};