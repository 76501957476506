import * as React from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import fetchData from '../../actions/utils/fetchData';
import { useValue } from '../../context/ContextProvider';
import { useEffect, useState } from 'react';

export default function Sizes() {

  const {
    dispatch
  } = useValue()

  const [tags, setTags] = useState([]);
  const url = process.env.REACT_APP_SERVER_PROD_URL + '/tag';

useEffect(() => {
  const retrieveTags = async () => {
    const tagsResponse = await fetchData({
      url,
      method: 'GET'
    })
    if (tagsResponse) {
        // Process the response to keep only the most recent tag for each title
        const uniqueTags = tagsResponse.reduce((map, tag) => {
          if (!map.has(tag.title) || new Date(tag.updatedAt) > new Date(map.get(tag.title).updatedAt)) {
            map.set(tag.title, tag);
          }
          return map;
        }, new Map());

        // Convert the map back to an array
        const processedTags = Array.from(uniqueTags.values());


        // Sort the tags alphabetically by title
        processedTags.sort((a, b) => a.title.localeCompare(b.title));
        setTags(processedTags);
    }
  }

  retrieveTags()
}, [url])

  // On Autocomplete change from multi-select bar
  const handleAutocompleteChange = (value) => {
    dispatch({type: 'UPDATE_FILTERED_TAGS', payload: value})
  }

  return (
    <Stack spacing={2} sx={{ width: 320 }}>
      <Autocomplete
        multiple
        onChange={(event, value) => {handleAutocompleteChange(value)}}
        id="size-small-standard-multi"
        size="small"
        options={tags}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select your Tags"
            placeholder="Add Another Tag"
          />
        )}
      />
    </Stack>
  );
}

