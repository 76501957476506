const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_LOGIN':
      return { ...state, openLogin: true };
    case 'CLOSE_LOGIN':
      return { ...state, openLogin: false };

    case 'START_LOADING':
      return { ...state, loading: true };
    case 'END_LOADING':
      return { ...state, loading: false };

    case 'UPDATE_ALERT':
      return { ...state, alert: action.payload };

    // Update filtered Tags reducer action
    case 'UPDATE_FILTERED_TAGS':
      return {...state, filteredTags: action.payload }

    case 'UPDATE_PROFILE':
      return { ...state, profile: action.payload };

    case 'UPDATE_USER':
      localStorage.setItem('currentUser', JSON.stringify(action.payload));
      return { ...state, currentUser: action.payload };

    case 'UPDATE_IMAGES':
      return { ...state, images: [...state.images, ...action.payload] };
    case 'DELETE_IMAGE':
      return {
        ...state,
        images: state.images.filter((image) => image !== action.payload),
      };
    case 'UPDATE_DETAILS':
      return { ...state, details: { ...state.details, ...action.payload } };

    case 'UPDATE_LOCATION':
      return { ...state, location: action.payload };
    case 'UPDATE_UPDATED_POINT':
      return { ...state, updatedPoint: action.payload };

    case 'UPDATE_DELETED_IMAGES':
      return {
        ...state,
        deletedImages: [...state.deletedImages, ...action.payload],
      };
    case 'UPDATE_ADDED_IMAGES':
      return {
        ...state,
        addedImages: [...state.addedImages, ...action.payload],
      };
    case 'RESET_POINT':
      return {
        ...state,
        images: [],
        details: { title: '', description: '', price: 0, pointTags: [] },
        location: { lng: 0, lat: 0 },
        updatedPoint: null,
        deletedImages: [],
        addedImages: [],
      };

    case 'RESET_POLYLINE':
      return {
        ...state,
        polyline_details: { polyline_title: '', polyline_description: '', polyline_encoded: '', polyline_tags: [] },
        updatedPolyline: null
      };
    case 'RESET_TAG':
        return {
          ...state,
          tagDetails: { Tagtitle: ''}
        };

    case 'UPDATE_POINTS':
      return {
        ...state,
        points: action.payload,
        addressFilter: null,
        priceFilter: 50,
        filteredPoints: action.payload,
      };

    case 'UPDATE_FILTERED_POINTS':
      return {
        ...state, 
        addressFilter: null,
        priceFilter: 50,
        filteredPoints: action.payload
      }      
    case 'UPDATE_TAGS':
      return {
        ...state,
        tags: action.payload,
        filteredTags: action.payload,
      };
    case 'FILTER_PRICE':
      return {
        ...state,
        priceFilter: action.payload,
        filteredPoints: applyFilter(
          state.points,
          state.addressFilter,
          action.payload
        ),
      };
    case 'FILTER_ADDRESS':
      return {
        ...state,
        addressFilter: action.payload,
        filteredPoints: applyFilter(
          state.points,
          action.payload,
          state.priceFilter
        ),
      };
    case 'CLEAR_ADDRESS':
      return {
        ...state,
        addressFilter: null,
        priceFilter: 50,
        filteredPoints: state.points,
      };

    case 'UPDATE_POINT':
      return { ...state, point: action.payload };

    case 'UPDATE_TAG_DETAILS':
        return { ...state, tagDetails: { ...state.tagDetails, ...action.payload }  };

    case 'UPDATE_POLYLINE_DETAILS':
      return { ...state, polyline_details: { ...state.polyline_details, ...action.payload }  };
      
    case 'UPDATE_USERS':
      return { ...state, users: action.payload };
    case 'DELETE_POINT':
      return {
        ...state,
        points: state.points.filter((point) => point._id !== action.payload),
      };

    case 'DELETE_POLYLINE':
      return {
        ...state,
        polylines: state.polylines.filter((polyline) => polyline._id !== action.payload),
      };

    case 'DELETE_TAG':
        return {
          ...state,
          tags: state.tags.filter((tag) => tag._id !== action.payload),
        };

    case 'UPDATE_SECTION':
        return { ...state, section: action.payload };
    
    // NEW CASE FOR UPDATING THE LANDING VIEW
    case 'UPDATE_HOME_VIEW': 
        return { ...state, currentView: action.payload };
    default:
      throw new Error('No matched action!');
  }
};

export default reducer;

const applyFilter = (points, address, price) => {
  let filteredPoints = points;
  if (address) {
    const { lng, lat } = address;
    filteredPoints = filteredPoints.filter((point) => {
      const lngDifference = Math.abs(lng - point.lng);
      const latDifference = Math.abs(lat - point.lat);
      return lngDifference <= 1 && latDifference <= 1;
    });
  }

  if (price < 50) {
    filteredPoints = filteredPoints.filter((point) => point.price <= price);
  }

  return filteredPoints;
};